<template>
    <div class="Expertise" id="ExpertisePage">
        <div class="ExpertiseIntro Wrapper-section" id="ExpertiseIntroPage">
            <div class="ExpertiseIntro-container">
                <header>
                    <h1 class="title ExpertiseIntro-title">We’re a niche B2B sales and marketing consultancy and software company.</h1>
                    <h2 class="subtitle ExpertiseIntro-subtitle">Focused on the growing disconnect between modern-day buyers and how sales and marketing engage with them.</h2>
                </header>
                <div class="ExpertiseVideo" id="ExpertiseVideo">
                    <figure class="ExpertiseVideo-media">
                        <div class="ExpertiseVideo-loading modal modal-on LoadingSpinner">
                            <div class="LoadingSpinner-spinner LoadingSpinner-spinner_loading"></div>
                        </div>
                        <img :src="videoThumb" alt="xpertiseVideo" class="ExpertiseVideo-preview">
                        <vue-plyr
                                :emit="['ready', 'beforeDestroy']"
                                @beforeDestroy="videoDestroy"
                                @ready="videoIsReady">
                            <video :data-plyr-config='plyrConfig' :poster="videoThumb" :src="videoSources[0].src">
                                <source :key="index" :size="video.size" :src="video.src" :type="video.type" v-for="(video, index) in videoSources" />
                            </video>
                        </vue-plyr>
                    </figure>
                </div>
            </div>
        </div>
        <div class="ExpertiseList Wrapper-section" id="ExpertiseListPage">
            <div class="container">
                <h1 class="title ExpertiseList-title">Mastering the art of SalesMaking</h1>
            </div>
            <div class="fullwidth">
                <div class="Expertise-container">
                    <nav class="ExpertiseNavigation">
                        <ExpertiseIcon
                                :headerWord="ex.headerWord"
                                :iconKey="ex.key"
                                :key="ex.index"
                                :label="ex.label"
                                :wrappedLabel="ex.wrappedLabel"
                                v-for="ex in expertises"
                        />
                    </nav>
                </div>
            </div>
        </div>
        <div class="fullwidth">
            <div class="ExpertiseContent">
                <ExpertiseContent
                        :bg="ex.bg"
                        :headerWord="ex.headerWord"
                        :iconKey="ex.key"
                        :id="`${ex.key}Page`"
                        :illustrations="ex.illustrations"
                        :index="index"
                        :key="ex.index"
                        :label="ex.label"
                        :pattern="ex.pattern"
                        :text="ex.text"
                        class="Wrapper-section"
                        v-for="(ex, index) in expertises"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { TimelineLite, Power2 } from 'gsap';
    import store from '@/store';

    export default {
        name: 'Expertise',
        components: {
            ExpertiseIcon: () => import('@/components/ExpertiseIcon'),
            ExpertiseContent: () => import('@/components/ExpertiseContent')
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                videoContainerClass: 'ExpertiseVideo',
                plyrConfig: `{
                    "volume": 1,
                    "rel": 0,
                    "debug": false,
                    "resetOnEnd": 1,
                    "controls": [
                        "restart",
                        "play-large",
                        "play",
                        "progress",
                        "current-time",
                        "duration",
                        "pip"
                    ],
                    "fullscreen": {
                        "enabled": false
                    }
                }`,
                sceneTimer: null,
                expertises: store.state.expertises
            };
        },
        computed: {
            scrollMagicSectionId() {
                let tmpSection = ['ExpertiseIntroPage', 'ExpertiseListPage'];
                for (let i = 0; i < this.expertises.length; i++) {
                    tmpSection.push(`${this.expertises[i].key}Page`);
                }
                return tmpSection;
            },
            videoSources() {
                return [
                    {
                        src: `${this.publicPath}videos/JFIExplainer_720.mp4`,
                        type: 'video/mp4',
                        size: '720'
                    },
                    {
                        src: `${this.publicPath}videos/JFIExplainer_720.webm`,
                        type: 'video/webm',
                        size: '720'
                    }
                ];
            },
            videoThumb() {
                return `${this.publicPath}videos/JFIExplainer.jpg`;
            }
        },
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            setBgClass: function (className) {
                store.dispatch('setBgClass', className);
            },
            videoIsReady() {
                let videoReadyTimeout = setTimeout(() => {
                    clearTimeout(videoReadyTimeout);
                    let videoContainer = document.querySelector('.' + this.videoContainerClass);
                    if (videoContainer) {
                        videoContainer.classList.add(this.videoContainerClass + '_ready');
                    }
                }, 250);
            },
            videoDestroy() {
                let videoContainer = document.querySelector('.' + this.videoContainerClass);
                if (videoContainer) {
                    videoContainer.classList.remove(this.videoContainerClass + '_ready');
                }
            },
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);

                // All Sections
                const SMC = new this.$scrollmagic.Controller();
                for (let i = 0; i < this.scrollMagicSectionId.length; i++) {
                    const scene = new this.$scrollmagic.Scene({
                        triggerElement: `#${this.scrollMagicSectionId[i]}`,
                        triggerHook: 'onEnter',
                        offset: 50
                    }).setClassToggle(`#${this.scrollMagicSectionId[i]}`, 'visible'); // add class to reveal
                    SMC.addScene(scene);
                }

                // B2B Digital Selling
                const DigitalSelling_pattern = '#ExpertisePanel-DigitalSelling_pattern';
                const tlDigitalSelling_pattern = new TimelineLite();
                tlDigitalSelling_pattern.to(DigitalSelling_pattern, .5, {
                    backgroundPositionY: '100%'
                });
                const DigitalSelling_pattern_Scene = new this.$scrollmagic.Scene({
                    triggerElement: DigitalSelling_pattern,
                    triggerHook: 'onEnter',
                    duration: '100%',
                    reverse: true
                }).setTween(tlDigitalSelling_pattern);
                SMC.addScene(DigitalSelling_pattern_Scene);

                // Go-to-Market Transformation (Previously: Steve)
                const GoToMarket_illustration1 = '#ExpertisePanel-GoToMarket_illustration1';
                const tlGoToMarket_illustration1 = new TimelineLite();
                tlGoToMarket_illustration1.to(GoToMarket_illustration1, .5, {
                    x: '10vw',
                    ease: Power2.easeInOut
                });
                const GoToMarket_illustration1_Scene = new this.$scrollmagic.Scene({
                    triggerElement: GoToMarket_illustration1,
                    triggerHook: .75,
                    duration: 500,
                    offset: 175
                }).setTween(tlGoToMarket_illustration1);
                SMC.addScene(GoToMarket_illustration1_Scene);

                // Sales Effectiveness (Previously Bike)
                const Sales_pattern = '#ExpertisePanel-Sales_pattern';
                const Sales_pattern_Scene = new this.$scrollmagic.Scene({
                    triggerElement: Sales_pattern,
                    triggerHook: 'onCenter',
                    offset: 150
                }).setClassToggle(Sales_pattern, 'visible');
                SMC.addScene(Sales_pattern_Scene);

                const Sales_circle_big = '#ExpertisePanel-Sales_circle_big';
                const Sales_circle_big_Scene = new this.$scrollmagic.Scene({
                    triggerElement: Sales_pattern,
                    triggerHook: 'onCenter',
                    offset: 150
                }).setClassToggle(Sales_circle_big, 'visible');
                SMC.addScene(Sales_circle_big_Scene);

                // Space
                const Strategic_circle_small = '#ExpertisePanel-Strategic_circle_small';
                const tlStrategic_circle_small = new TimelineLite();
                tlStrategic_circle_small.to(Strategic_circle_small, .5, {
                    left: 10,
                    right: `auto`
                });
                const Strategic_circle_small_Scene = new this.$scrollmagic.Scene({
                    triggerElement: Strategic_circle_small,
                    triggerHook: .75,
                    duration: 700,
                    offset: 175
                }).setTween(tlStrategic_circle_small);
                SMC.addScene(Strategic_circle_small_Scene);

                const Strategic_circle_big = '#ExpertisePanel-Strategic_circle_big';
                const tlStrategic_circle_big = new TimelineLite();
                tlStrategic_circle_big.to(Strategic_circle_big, .5, {
                    left: `auto`,
                    right: 10
                });
                const Strategic_circle_big_Scene = new this.$scrollmagic.Scene({
                    triggerElement: Strategic_circle_big,
                    triggerHook: .75,
                    duration: 500,
                    offset: 175
                }).setTween(tlStrategic_circle_big);
                SMC.addScene(Strategic_circle_big_Scene);

                // Creation
                const ContentCreation_pattern = '#ContentCreationPage .ExpertisePanel-focus div';
                const tlContentCreation_pattern = new TimelineLite();
                tlContentCreation_pattern.staggerFrom(ContentCreation_pattern, 1.5, {
                    rotationZ: '-180deg',
                    left: '100vw',
                    ease: Power2.easeOut
                }, .08);
                const ContentCreation_pattern_Scene = new this.$scrollmagic.Scene({
                    triggerElement: '#ContentCreationPage',
                    triggerHook: .75,
                    offset: 175
                }).setTween(tlContentCreation_pattern);
                SMC.addScene(ContentCreation_pattern_Scene);
            }
        }
    };
</script>
